export default {
  meta_title: 'Demos | PennyLane',
  meta_description:
    'Take a deeper dive into quantum computing by exploring cutting-edge algorithms using PennyLane and quantum hardware.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/demonstrations/pennylane_demos_hero_illustration.png',
  title: 'Demos',
  text_1: 'Take a deeper dive into quantum computing by exploring cutting-edge algorithms using PennyLane and quantum hardware.',
  building_with_pennylane: {
    title: ``,
    buttons: [],
  },
}
