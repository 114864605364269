import { useMemo } from 'react'
import { demosCategories } from '../../../content/demos/demonstrations_categories'
import { graphql, useStaticQuery } from 'gatsby'
import LinkComponent from '../LinkComponent/LinkComponent'
import { IItemsCarouselCard } from '../ItemsCarousel/ItemsCarousel'
import CategoryCarousel from '../CategoryCarousel/CategoryCarousel'
import { getImageByTypeFromMetaData } from '../../utils/demo_helpers'
import { slugifyString } from 'shared-utilities'
import {
  ContentType,
  contentSortParams,
  createSearchPageRoute,
} from 'shared-utilities'
import './style.scss'

export interface IDemoMarkdownNode {
  frontmatter: {
    slug: string
    title: string
    meta_description: string
    categories: string[]
    previewImages: {
      uri: string
      type: string
    }[]
  }
  id: string
}
export interface IDemosMarkdownRemarkQuery {
  allMarkdownRemark: {
    nodes: IDemoMarkdownNode[]
  }
}

export default function DemoCategoryList() {
  const { allMarkdownRemark } =
    useStaticQuery<Queries.GetAllDemosByPublicationDateQuery>(graphql`
      query GetAllDemosByPublicationDate {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/demos/" } }
          sort: { fields: frontmatter___date_of_publication, order: DESC }
        ) {
          nodes {
            frontmatter {
              categories
              title
              slug
              meta_description
              previewImages {
                type
                uri
              }
            }
          }
        }
      }
    `)

  const demosCategoryMap: { [key: string]: IItemsCarouselCard[] } =
    useMemo(() => {
      const demos = allMarkdownRemark.nodes
      const demosCategoryMapLocal = demosCategories.reduce<{
        [key: string]: IItemsCarouselCard[]
      }>((acc, category) => {
        if (category.title) acc[category.title] = []
        return acc
      }, {})

      // Filter demos with categories
      const demosWithCategories = demos.filter(
        (demo) =>
          demo.frontmatter?.categories &&
          demo.frontmatter.categories.length &&
          !demo.frontmatter.categories.includes('')
      )

      // Add demos in their respective categories
      demosWithCategories.forEach((demo) => {
        demo.frontmatter?.categories?.forEach((category) => {
          if (category && demosCategoryMapLocal[category]) {
            const thumbnail = getImageByTypeFromMetaData(
              demo.frontmatter?.previewImages?.map((image) => ({
                type: image?.type || '',
                uri: image?.uri || '',
              })) || [],
              'thumbnail'
            )

            // if there are no slugs or label we don't add the demo to the category
            if (demo.frontmatter?.slug && demo.frontmatter.title) {
              demosCategoryMapLocal[category].push({
                link: `/qml/demos/${demo.frontmatter.slug}`,
                slug: demo.frontmatter.slug,
                label: demo.frontmatter.title,
                image: thumbnail ? thumbnail : '',
              })
            }
          }
        })
      })

      return demosCategoryMapLocal
    }, [allMarkdownRemark])

  return (
    <div className="DemoCategoryList">
      <div className="max-w-[1300px] mx-auto px-3">
        {Object.entries(demosCategoryMap).map(([key, value]) =>
          value && value.length ? (
            <div key={key} className="mb-20">
              <CategoryCarousel
                title={
                  <CategoryLink
                    category={key}
                    displayText={key}
                    className="text-black"
                  />
                }
                seeAllLink={
                  <CategoryLink
                    category={key}
                    displayText={`See all (${value.length})`}
                  />
                }
                categoryList={value.slice(0, 10)}
              />
            </div>
          ) : null
        )}
      </div>
    </div>
  )
}

type CategoryLinkProps = {
  category: string
  displayText: string
  className?: string
}

/* Link Component use for these sections only. */
const CategoryLink = ({
  category,
  displayText,
  className = '',
}: CategoryLinkProps) => (
  <LinkComponent
    className={className}
    href={createSearchPageRoute({
      category,
      contentType: ContentType.DEMO,
      sort: contentSortParams.PUBLICATION_DATE_DESC,
    })}
    id={slugifyString(displayText)}
  >
    {displayText}
  </LinkComponent>
)
